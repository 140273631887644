import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import waitingImage from './../assets/waitingman.gif';
import io from "socket.io-client";
import * as CONSTANTS from "../CONSTANTS";
import ScrollToBottom from "react-scroll-to-bottom";
import { logout } from '../reduxAuth/authSlice';
const socket  = io.connect(CONSTANTS.API_BASE);

function ChatPage() {
    const {user}                                              = useSelector((state) => state.auth);
    const [processing, setProcessing]                         = useState(false);
    const params                                              = useParams();
    const [thisScript, setThisScript]                         = useState();
    const [pharmItem, setPharmItem]                                     = useState();

    const [requestToJoinRoom, setRequestToJoin]                         = useState(false);
    const [chatidString, setChatidString]                               = useState("");
    const [reasonForApprove, setReasonForApprove]                       = useState("");
    
    const [joinApproved, setJoinApproved]                               = useState(false);
    const [currentMessage, setCurrentMessage]                           = useState();
    const [messageList, setMessageList]                                 = useState([]);

    const dispatch                                                           = useDispatch();

    
    useEffect(() => { 
        socket.on("receive_message", (data) => { 
            setMessageList((list) => [...list, data]); 
        }); 

    }, [socket]);

    useEffect(()=> {
        getThisScript();
        getCurrentPharmacy();
    },[])

    useEffect(() => {
        socket.on("receive_message", (data) => {
            console.log("Client socket");
            console.log(data);
            setMessageList((list) => [...list, data]);
        });
    
    },[socket]);

    const getThisScript = async () => {

        try {
          
            const res = await axios.get(CONSTANTS.API_URL +"scripts/details/"+ params.id, {
                 headers: {
                     token: "Bearer "+ user.accessToken
                 }
             });
   
            //console.log(res.data);
            setThisScript(res.data);
          
          } catch (err) {
            console.log(err);
            console.log("ERRRRRRR Script");
            //Check if token is invalid     
            if(err.response.status === 403){
                localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
                dispatch(logout());
                toast.error("Session has timeout out.")
                setTimeout(() => {
                  document.location.href("/login");
                }, 4000);
              }
          }      
    }

    const sendMessageChat = async() => {
        try{
         //patientDetails
            if(currentMessage !== "" ){
                const messageData = {
                    room: user.pharmacycode,
                    chatid: chatidString,
                    message: currentMessage,
                    time: new Date(Date.now()).getHours() + ":" + new Date(Date.now()).getMinutes(),
                    author: {
                        senderid: user._id,
                        sendername: user.name
                      }
                }

                //console.log("((((((((((((((((((((");
                //console.log(messageData);
                await socket.emit("send_message", messageData);
                setMessageList((list) => [...list, messageData]);
                setCurrentMessage("");
            }
        }catch(err){
            console.log(err);
        }
    }
    
    const requestToJoinChatMethod = async () => {
        try{

            const randChatID = Math.floor(Math.random() * 9000) + 1000;
            setChatidString(randChatID.toString());
            const chatIdList = [];
            chatIdList.push(randChatID.toString());

            const messageData = {
                room: user.pharmacycode,
                message: reasonForApprove,
                chatid: chatIdList,
                scriptcode: thisScript.scriptcode,
                titlePharm: pharmItem.title,
                author: user._id
            }

            console.log(messageData);
            const res = await axios.post(CONSTANTS.API_URL +"users/create/flag", messageData, {
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            });

           //console.log(res);
           if(res.status === 200 ){
                setRequestToJoin(true);
                
                //console.log("Room ID");
                //console.log(user.pharmacycode);
                const curOb = {
                    "roomid" : user.pharmacycode,
                }
                
                //Change Flag to Seen
                const resMessageHistory = await axios.put(CONSTANTS.API_URL + "settings/message/v1/history", curOb, {
                    headers: {
                        token: "Bearer "+ user.accessToken
                    }
                });

                setMessageList(resMessageHistory.data);
                /////////////////////////////
                //create Socket Connection
                socket.emit("join_room", user.pharmacycode);
                setJoinApproved(true);
           }
        }catch(err) {
            console.log(err);
        }
    }

    const handleApprovalReason = (eventInfo) => {        
        setReasonForApprove(eventInfo.target.value);
    }

    const getCurrentPharmacy = async () => {
        try{

            const res = await axios.get(CONSTANTS.API_URL +"pharmacy/pharmcode/"+ user.pharmacycode, {
                        headers: {
                            token: "Bearer "+ user.accessToken
                        }
                    });  
         
           setPharmItem(res.data);  
        }catch(err){
            console.log(err);
        }
    }
  return (
    <div className="main-standard-version">
      <h2 className="lbl-title-page">Request Approval</h2>
        <div className="card">
            <div className="card-body">
                <div className="flexme">
                    <div className="flexfit50 chat-area-info ">
                       {
                        thisScript && (
                            <div className="content">
                                <p>
                                    Script Code - <strong>{thisScript.scriptcode}</strong>
                                </p>
                                <p>
                                    Full Name - {thisScript.fullname}
                                </p>
                                <p>
                                   ID Number - {thisScript.idNumber}
                                </p>
                                
                                {
                                        (joinApproved === false) && (
                                            <div className="">
                                                  <p>Why do you want to request an approval?</p>
                                                  <div className="form-group">
                                                        <textarea 
                                                            type="text"
                                                            className="form-control txtarea3"
                                                            onChange={handleApprovalReason}
                                                            ></textarea>                                                            
                                                  </div>
                                                 <button className="btn btn-rescure" onClick={() => requestToJoinChatMethod()} >Request Chat Approval</button>
                                            </div>
                                        )
                                    }
                            </div>
                        )
                       }
                    </div>
                    <div className="flexfit50 chat-area-info ">
                       {
                        joinApproved && (
                            <>
                               {
                                   messageList && messageList.length > 0 ?
                                     <div className="home-chat">
                                        <div className="chat-header">
                                                <p>Live Chat</p>
                                        </div>
                                        <div className="chat-body">  
                                            <div className="chat-window-body" >
                                            <ScrollToBottom className="control-chat-scroll">
                                            {
                                                    messageList.map((message, index) => {
                                                        return <div className={`message-buble ${user._id === message.author.senderid ? "your-message": "others-message" }`} key={index}>
                                                                <div className="titling-buble">
                                                                    {message.author.sendername}
                                                                </div>
                                                                <div className="message-text">
                                                                <p>{message.message}</p>
                                                                </div>
                                                                <div className="time-meta">{message.time}</div>
                                                            </div>
                                                    })
                                                }
                                            </ScrollToBottom>
                                            </div>                          
                                        </div>
                                        <div className="chat-footer">
                                            <input 
                                                className="form-control txt-message"
                                                type="text"
                                                placeholder="Type Message..."
                                                onChange={(event) => {
                                                    setCurrentMessage(event.target.value);
                                                }}       
                                                value={currentMessage}
                                                onKeyPress={(event) => {
                                                    event.key === "Enter" && sendMessageChat();
                                                }}
                                                />                                            
                                                <button className="btn btn-rescure" onClick={sendMessageChat}>&#9658;</button>
                                        </div>
                                     </div>
                                     : 
                                       <div className="awaiting-box">
                                            <div className="inner-awaiting">
                                                <img src={waitingImage} className="image-inner-await" />
                                            </div>
                                            <h4>Please wait...</h4>
                                       </div>
                               }
                                    
                                </>
                        )
                       }
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ChatPage