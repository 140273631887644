import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import * as CONSTANTS from "../CONSTANTS";
import axios from 'axios';
import { toast } from 'react-toastify';

function Profile() {
  const [processing, setProcessing]                      = useState(false);
  const {user}                                           = useSelector((state) => state.auth);


  const nameRef                                       = useRef();
  const surnameRef                                    = useRef();
  const phoneNumberRef                                = useRef();
  const emailUserRef                                  = useRef();
  const professionRef                                 = useRef();

  console.log(user)
  console.log("PROFILE");
  
  async function HandleEditingProfileUpdate(e) {
    e.preventDefault();  
    setProcessing(true);   

    
    try{

      const newUser = {
        "userID": user._id, 
        "user": {
            "name": nameRef.current.value,
            "surname": surnameRef.current.value,
            "phone": phoneNumberRef.current.value,
            "profession": professionRef.current.value
        }
    }

    const resUpdate = await axios.put(CONSTANTS.API_URL +"users/update/userdetails", newUser,{
        headers: {
            token: "Bearer "+ user.accessToken
        }
    });
      toast.success("You successfully updated the user details.")

      setProcessing(false);   
    }catch(err){
      setProcessing(false);   
      toast.error("Something went wrong, please try again later.")
       console.log(err);
    }
  }
  
  return (
    <div className="main-standard-version">
      <h2 className="lbl-title-page">Profile</h2>
      {
            processing && (
                <div className="img-source">
                   <img src={CONSTANTS.SMALL_PROCESSING} className="proc" />
                </div>
            )
        }

      <div className="card">
         <div className="card-body">
          <div className="data-sectionarea">
              <h4 className="lbl-title-inner">Personal Details</h4>
                <div className="form-content ">

                    <form onSubmit={HandleEditingProfileUpdate}>  
                          <div className="frow">       
                                  <div className="form-group">
                                      <div className="lbldesc">Name:</div>
                                      <input type="text" className="form-control wide100" ref={nameRef} defaultValue={user.name} required/>
                              </div>                      
                              <div className="form-group">
                                      <div className="lbldesc">Surname:</div>
                                      <input type="text" className="form-control wide100" ref={surnameRef} defaultValue={user.surname} required/>
                              </div>
                              <div className="form-group">
                                      <div className="lbldesc">Profession:</div>
                                      <input type="text" className="form-control wide100" ref={professionRef} defaultValue={user.profession} required/>
                              </div>  
                              <div className="form-group">
                                      <div className="lbldesc">Phone Number:</div>
                                      <input type="text" className="form-control wide100" ref={phoneNumberRef} defaultValue={user.phone} required/>
                              </div>  
                          </div>
                        
                          <div className="form-group">
                              <button type="submit" className="btn btn-rescure" disabled={processing}>Update</button>                             
                          </div>

                      </form>
                </div>
            </div>
            <div className="data-sectionarea topborder">
                <h4 className="lbl-title-inner">Email Update</h4>
                <div className="">
                    <p>{user.email}</p>
                </div>
            </div>
          </div>

      </div>
    </div>
  )
}

export default Profile